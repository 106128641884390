import React, { useEffect, useState } from "react";
import "./profile.css";
// import profileImage from "../../assets/images/profile-img.png";
import placeholderImg from "../../assets/images/defaultprofile.jpg";
import Api from "../Api/Api";
import { useForm } from "react-hook-form";
import { BiSolidEdit } from "react-icons/bi";
import ChangePassword from "./ChangePassword";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { useProfile } from "../provider/ProfileProvider";
import Datepickercomponent from "../util/Datepicker";
import debounce from "lodash.debounce";
function MyProfile() {
  const user_email = localStorage.getItem("email_id");
  const moment = require("moment");
  const { toggleProfile } = useProfile();
  const { EditProfile, GetUserProfile } = Api();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [image, setImage] = useState(null);
  const [imageBinary, setImageBinary] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [ProfileImg, setProfileImg] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [date_of_birth, setDate_of_birth] = useState(null);
  const [imageBase64, setImageBase64] = useState("");

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageBase64(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const onSubmit = async (data, event) => {
    console.log(data);
    // if (Object.keys(errors).length > 0 || isInputFieldsEmpty(data)) {
    //   return;
    // }
    setIsSubmitting(true);
    let body = {
      first_name: data.FirstName,
      last_name: data.LastName,
      profile_image: imageBase64,
      dob: selectedDate,
      present_address: data.presentaddress,
      parmanent_address: data.permanentaddress,
      city: data.city,
      country: data.country,
      postal_code: data.postalcode,
    };

    try {
      const result = await EditProfile(body);
      if (result.success) {
        await toggleProfile();
        setImageBase64("");
        await GetProfile();
        toast.success(result.message);
      } else {
        // Handle login failure

        toast.error(result.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const GetProfile = async () => {
    try {
      const response = await GetUserProfile();

      setSelectedDate(response?.parameters.dob);
      // localStorage.setItem("user_name", response?.parameters?.first_name);
      setValue("FirstName", response?.parameters.first_name);
      setValue("LastName", response?.parameters.last_name);
      setValue("dob", response?.parameters.dob);
      // const defaultMoment = moment( response?.parameters.dob, 'DD/MM/YYYY'); // Convert to moment object
      // const defaultDateJS = defaultMoment.toDate();
      setSelectedDate(response?.parameters.dob);
      // setDate_of_birth(response?.parameters.dob);
      setValue("presentaddress", response?.parameters.present_address);
      setValue("permanentaddress", response?.parameters.parmanent_address);
      setValue("city", response?.parameters.city);
      setValue("postalcode", response?.parameters.postal_code);
      setValue("country", response?.parameters.country);
      setProfileImg(response?.parameters.profile_image);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  useEffect(() => {
    // Profile();
    GetProfile();
  }, []);

  const [activeTab, setActiveTab] = useState("editProfile");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleDate = (date) => {
    if (date) {
      const fromDate = date ? moment(date).format("DD/MM/YYYY") : "";
      setSelectedDate(fromDate);
      // setSelectedDate(null);
      // const formatdate = moment(date).format("DD/MM/YY");
    }
  };
  const minDate = new Date(
    new Date().setFullYear(new Date().getFullYear() - 100)
  ); // 100 years ago
  const maxDate = new Date(); // Current date
  const debouncedSetSearchQuery = debounce((value) => {
    onSubmit(value);
  }, 300);
  return (
    <div className="page_container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="breadcrumb">
              {/* <ul>
                <li className="active">
                  <Link to="#">Home</Link>
                </li>
                <li>My Profile</li>
              </ul> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="page_title">My Profile</div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="profile_card">
              <div className="profile_title">
                <div className="profile_tabs mb-3">
                  <button
                    onClick={() => handleTabClick("editProfile")}
                    className={activeTab === "editProfile" ? "active" : ""}
                  >
                    Edit Profile
                  </button>
                  <button
                    onClick={() => handleTabClick("security")}
                    className={activeTab === "security" ? "active" : ""}
                  >
                    Security
                  </button>
                </div>
                <div>
                  <h6>{user_email}</h6>
                </div>
              </div>

              {activeTab === "editProfile" && (
                <div>
                  <form
                    className="profile_form"
                    onSubmit={handleSubmit((data, event) => {
                      debouncedSetSearchQuery(data);
                      // Prevent default form submission
                      event.preventDefault();
                    })}
                  >
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-2">
                        <div className="edit_profile-img position-relative">
                          {/* {image ? (
                                                        <img src={image} alt="profile" />
                                                    ) : (
                                                        <img src={placeholderImg} alt='no image' />
                                                    )} */}
                          {imageBase64 ? (
                            <div>
                              <img src={imageBase64} alt="Profile" />
                            </div>
                          ) : (
                            <>
                              {" "}
                              <img
                                src={ProfileImg ? ProfileImg : placeholderImg}
                                alt="Profile"
                              />
                            </>
                          )}
                          <div className="img_edit">
                            <BiSolidEdit className="edit_icon" />
                            {/* <input type="file" onChange={handleImageChange} /> */}
                            <input
                              type="file"
                              accept="image/*"
                              //   onChange={handleImageUpload}
                              onChange={(event) => {
                                const file = event.target.files[0];
                                if (file) {
                                  const fileType = file.type.split("/")[0];

                                  if (fileType === "image") {
                                    // Handle image upload
                                    handleImageUpload(event);
                                  } else {
                                    // Reset file input field
                                    event.target.value = null;
                                    // Display error message
                                    toast.error(
                                      "Please select an image file to upload."
                                    );
                                  }
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10">
                        <div className="row">
                          <div className="col-12 col-sm-6 col-md-6 mb-3">
                            <div class="">
                              <label for="FirstName" class="form-label">
                                First Name
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  errors.FirstName ? "is-invalid" : ""
                                }`}
                                onKeyPress={(e) => {
                                  const charCode = e.which
                                    ? e.which
                                    : e.keyCode;
                                  if (
                                    !(charCode >= 65 && charCode <= 90) && // A-Z
                                    !(charCode >= 97 && charCode <= 122) && // a-z
                                    charCode !== 8 && // Backspace
                                    charCode !== 9
                                  ) {
                                    // Tab
                                    e.preventDefault();
                                  }
                                }}
                                id="FirstName"
                                name="FirstName"
                                placeholder="Enter First Name"
                                {...register("FirstName", { required: false })}
                              />

                              {errors.FirstName && (
                                <span className="invalid-feedback">
                                  First Name is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-6 mb-3">
                            <div class="">
                              <label for="LastName" class="form-label">
                                Last Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="LastName"
                                placeholder="Enter Last Name"
                                name="LastName"
                                onKeyPress={(e) => {
                                  const charCode = e.which
                                    ? e.which
                                    : e.keyCode;
                                  if (
                                    !(charCode >= 65 && charCode <= 90) && // A-Z
                                    !(charCode >= 97 && charCode <= 122) && // a-z
                                    charCode !== 8 && // Backspace
                                    charCode !== 9
                                  ) {
                                    // Tab
                                    e.preventDefault();
                                  }
                                }}
                                {...register("LastName", { required: false })}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-6 mb-3">
                            <div class="">
                              <label for="dob" class="form-label">
                                Date of Birth
                              </label>
                              {/* <input
                                type="date"
                                className={`form-control ${
                                  errors.dob ? "is-invalid" : ""
                                }`}
                                id="dob"
                                placeholder="Enter DOB"
                                name="dob"
                                {...register("dob", { required: true })}
                                // min={getTomorrow()}
                                // min={minDate}
                                // max={maxDate}
                              /> */}
                              <div className="form-control ">
                                <Datepickercomponent
                                  name="dob"
                                  {...register("dob", { required: false })}
                                  selecteddate={selectedDate}
                                  handleDate={handleDate}
                                  placeholderText="Enter DOB"
                                  minDate={minDate}
                                  maxDate={maxDate}
                                  defaultvalue={date_of_birth}
                                />
                              </div>

                              {errors.dob && (
                                <span className="invalid-feedback">
                                  Date of Birth is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-6 mb-3">
                            <div class="">
                              <label for="presentaddress" class="form-label">
                                Present Address
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  errors.presentaddress ? "is-invalid" : ""
                                }`}
                                id="presentaddress"
                                placeholder="Enter Present Address"
                                name="presentaddress"
                                onKeyDown={(e) => {
                                  if (
                                    e.target.selectionStart === 0 &&
                                    e.key === " "
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                {...register("presentaddress", {
                                  required: false,
                                })}
                              />
                              {errors.presentaddress && (
                                <span className="invalid-feedback">
                                  Present Address is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-6 mb-3">
                            <div class="">
                              <label for="permanentaddress" class="form-label">
                                Permanent Address
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  errors.permanentaddress ? "is-invalid" : ""
                                }`}
                                id="permanentaddress"
                                placeholder="Enter Permanent Address"
                                name="permanentaddress"
                                onKeyDown={(e) => {
                                  if (
                                    e.target.selectionStart === 0 &&
                                    e.key === " "
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                {...register("permanentaddress", {
                                  required: false,
                                })}
                              />
                              {errors.permanentaddress && (
                                <span className="invalid-feedback">
                                  Permanent Address is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-6 mb-3">
                            <div class="">
                              <label for="city" class="form-label">
                                City
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  errors.city ? "is-invalid" : ""
                                }`}
                                id="city"
                                placeholder="Enter City Name"
                                name="city"
                                onKeyDown={(e) => {
                                  if (
                                    e.target.selectionStart === 0 &&
                                    e.key === " "
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                {...register("city", { required: false })}
                              />
                              {errors.city && (
                                <span className="invalid-feedback">
                                  City is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-6 mb-3">
                            <div class="">
                              <label for="postalcode" class="form-label">
                                Postal Code
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  errors.postalcode ? "is-invalid" : ""
                                }`}
                                id="postalcode"
                                placeholder="Enter Postal Code"
                                name="postalcode"
                                onKeyPress={(event) => {
                                  // Allow only numbers
                                  const isValidKey = /^\d$/.test(event.key);
                                  if (!isValidKey) {
                                    event.preventDefault();
                                  }
                                }}
                                {...register("postalcode", { required: false })}
                              />
                              {errors.postalcode && (
                                <span className="invalid-feedback">
                                  Postal Code is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-6 mb-3">
                            <div class="">
                              <label for="country" class="form-label">
                                Country
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  errors.country ? "is-invalid" : ""
                                }`}
                                id="country"
                                placeholder="Enter Country"
                                name="country"
                                onKeyDown={(e) => {
                                  if (
                                    e.target.selectionStart === 0 &&
                                    e.key === " "
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                {...register("country", { required: false })}
                              />
                              {errors.country && (
                                <span className="invalid-feedback">
                                  Country is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-12 mb-3">
                            <div class="texts-end">
                              <button
                                className="btn form_save-btn"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}

              {activeTab === "security" && (
                <div>
                  <div className="row">
                    <div className="col-12">
                      <h3 className="password_title">Change Password</h3>
                      <ChangePassword />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyProfile;
