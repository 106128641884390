import React, { useEffect, useRef, useState } from "react";
import filter_icon from "../../assets/images/filter_icon.svg";
import reload_icon from "../../assets/images/reload_icon.svg";
import { CiSearch } from "react-icons/ci";
import Api from "../Api/Api";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { IoClose, IoCloseCircle } from "react-icons/io5";
import playIcon from "../../assets/images/play_icon.svg";
import fbIcon from "../../assets/images/fb_icon.svg";
import twitterIcon from "../../assets/images/twitter_icon.svg";
import { useForm } from "react-hook-form";
import debounce from "lodash.debounce";
function MeetingList() {
  const { register, setValue } = useForm();
  const moment = require("moment");
  const user_role = localStorage.getItem("user_role");
  console.log(user_role);
  const dataTableRef = useRef(null);
  const { TitleMeetingCount, getadmin_meeting_List } = Api();
  const [MeetingData, setMeetingData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [TotalCount, setTotalCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const [PerPageCount, setPerPageCount] = useState("");
  const currentRows = MeetingData.slice(indexOfFirstRow, indexOfLastRow);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleVisibility = () => {
    setIsOpen(!isOpen);
  };

  const handleNextPage = async () => {
    setCurrentPage(currentPage + 1);
    handleFilterChange({
      currentPage: currentPage + 1,
      meeting: searchQuery,
    });
    if (window.innerWidth > 768) {
      // Adjust 768 according to your design
      window.scrollTo(0, 0);
    }
  };

  const handlePrevPage = async () => {
    setCurrentPage(currentPage - 1);
    handleFilterChange({
      currentPage: currentPage - 1,
      meeting: searchQuery,
    });
    if (window.innerWidth > 768) {
      // Adjust 768 according to your design
      window.scrollTo(0, 0);
    }
  };

  const debouncedSetSearchQuery = debounce((value) => {
    setSearchQuery(value);
    setCurrentPage(1);
    handleFilterChange({
      meeting: value,
      currentPage: "0",
    });
  }, 300);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup function to reset the overflow when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);
  const handleSearchChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    debouncedSetSearchQuery(value);
  };
  const handleFilterChange = async ({ meeting, currentPage }) => {
    try {
      //   const meetTitle = document.getElementsByName("title")[0].value;
      setLoading(true);
      const filters = {
        title: meeting,
        page: currentPage.toString(),
      };
      const response = await TitleMeetingCount(filters);
      setMeetingData(response.parameters);
      setTotalCount(response.pagination.total);
      setPerPageCount(response.pagination.to);
      setLoading(false);

      // Handle response here
    } catch (error) {
      setLoading(false);
      console.error("Error occurred during register:", error);
    }
  };

  // const handleAdminlist = async () => {
  //   try {
  //     const response = await getadmin_meeting_List({
  //       meeting: "",
  //       currentPage: "0",
  //     });
  //     console.log({ "admin list": response });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  // useEffect(() => {
  //   handleAdminlist();
  // }, []);

  const handleResetFilters = () => {
    // document.getElementsByName("title")[0].value = "";
    setCurrentPage(1);
    handleFilterChange({
      meeting: "",
      currentPage: "0",
    });
    setValue("search", "");
  };

  const [first, setFirst] = useState(true);
  const handleFirst = async () => {
    try {
      setFirst(false);
      await handleFilterChange({
        meeting: "",
        currentPage: "0",
      });
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
      setFirst(true);
    }
  };
  useEffect(() => {
    if (first) {
      handleFirst();
    }
  }, [first]);

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="mob_fil_align">
            <div className="page_title title_wise">
              Meeting Title wise Statistics
            </div>
            <div
              className="mobile_filter_icon mob_fills_icon"
              onClick={toggleVisibility}
            >
              <img src={filter_icon} alt="filter" />
            </div>
          </div>
        </div>
      </div>
      <div className="row desktop_filter align-items-center mb-3 mt-2">
        <div className="col-12 col-sm-12 col-md-12">
          {/* <div className={`filter_container ${isOpen ? "open" : ""}`}> */}
          {/* <div
              className="filter_icon filter_col sm_flex-end"
              onClick={toggleVisibility}
            >
              <img src={filter_icon} alt="filter" className="hide_mobile" />
              <IoClose className="show_mobile filter_close text-end" />
            </div> */}
          <div className={`filter_container ${isOpen ? "open" : ""}`}>
            <div
              className="filter_icon filter_col sm_flex-end hide_mobile"
              onClick={toggleVisibility}
            >
              <img src={filter_icon} alt="filter" className="" />
              {/* <IoClose className="show_mobile filter_close" /> */}
            </div>

            <div className="close_sidemenu_mobile" onClick={toggleVisibility}>
              {isOpen && (
                <IoCloseCircle
                  className="close_icon_mobile_response"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                />
              )}
            </div>
            <div className="fw-bold filter_title filter_col d-flex align-items-center justify-content-center">
              Search
            </div>
            <form className="d-flex align-items-center meeting_form">
              <div className="status_filter filter_col">
                <div className="name_filter position-relative">
                  <input
                    type="text"
                    name="search"
                    placeholder="Search"
                    {...register("search")}
                    onChange={handleSearchChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault(); // Prevent form submission
                        // handleSearchChange(e.target.value); // Pass only the value
                      }
                    }}
                  />
                  <span>
                    <CiSearch />
                  </span>
                </div>
              </div>

              <div
                className="white-space-nowrap reset_filter filter_col d-flex align-items-center justify-content-center"
                onClick={handleResetFilters}
              >
                <img src={reload_icon} alt="reset" className="me-2" /> Reset
                Filter
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="table-responsive main_datatable">
        <table
          ref={dataTableRef}
          className="table custom_datatable"
          style={{ width: "100%" }}
        >
          <thead>
            <tr>
              <th>ID</th>
              <th>TITLE</th>
              <th className="text-center">
                <img src={playIcon} alt="play" />
              </th>
              <th className="text-center">
                <img src={fbIcon} alt="facebook" />
              </th>
              <th className="text-center">
                <img src={twitterIcon} alt="twitter" />
              </th>
            </tr>
          </thead>

          {loading ? (
            // Loader component or loading indicator
            <tbody>
              <tr>
                <td colSpan="5" className="text-center ">
                  Loading...
                </td>
              </tr>
            </tbody>
          ) : (
            // Your regular UI content
            <tbody>
              {MeetingData && MeetingData.length > 0 ? (
                MeetingData.map((meet, index) => (
                  <tr key={meet.id}>
                    <td className="text-center">
                      {indexOfFirstRow + index + 1}
                    </td>
                    <td>
                      {meet.video_title && meet.video_title}
                      <br />
                      <small>
                        <div className="d-flex justify-content-between">
                          {" "}
                          <span>{meet.video_date}</span>{" "}
                          <span>
                            {" "}
                            {meet.from_time
                              ? moment(meet.from_time, "HH:mm:ss").format(
                                  "HH:mm"
                                )
                              : "00:00"}
                            -
                            {meet.to_time
                              ? moment(meet.to_time, "HH:mm:ss").format("HH:mm")
                              : "00:00"}
                          </span>
                        </div>
                      </small>
                    </td>
                    <td className="text-center">
                      {meet.channels.YOUTUBE ? meet.channels.YOUTUBE : "0"}
                    </td>
                    <td className="text-center">
                      {meet.channels.FACEBOOK ? meet.channels.FACEBOOK : "0"}
                    </td>
                    <td className="text-center">
                      {meet.channels.TWITTER ? meet.channels.TWITTER : "0"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    No meetings found for the selected filters.
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
      {MeetingData.length > 0 && (
        <div className="d-flex align-items-center justify-content-between">
          <p className="m-0 table_page-count">
            Showing {indexOfFirstRow + 1}-{PerPageCount} of {TotalCount}
          </p>
          <div className="d-flex align-items-center justify-content-end table_nav">
            <button
              disabled={currentPage === 1 || currentPage === 0}
              onClick={handlePrevPage}
            >
              <FaAngleLeft />
            </button>
            <button
              // disabled={indexOfLastRow >= MeetingData.length}
              disabled={PerPageCount == TotalCount}
              onClick={handleNextPage}
            >
              <FaAngleRight />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default MeetingList;
