import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Api from "../Api/Api";
import toast, { Toaster } from "react-hot-toast";
import Dropdown from "../util/Dropdown";
function AddUser() {
  const { AddUserAPI } = Api();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    trigger,
    watch,
    formState: { errors },
  } = useForm();
  const options = [
    { value: "ADMIN", label: "Admin" },
    { value: "USER", label: "User" },
  ];
  // const onSubmit = async (value) => {
  //     try {
  //         const authToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2RldjQudGVjaG5vdGFja2xlLmluL3BhcmxpYW1lbnQvcHVibGljL2FwaS9sb2dpbiIsImlhdCI6MTcxMDc1OTMyNiwiZXhwIjoxNzEwNzYyOTI2LCJuYmYiOjE3MTA3NTkzMjYsImp0aSI6Im1qZXlINm1xMHlOV09wangiLCJzdWIiOiIxIiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.Bn-7qgLPBW2v29rlFGWFEAZEegwiXqw8-h71Vc5x9wQ";
  //         const AUTHORIZATION = "d3215a471380f7cbbcb484ae7ec0fe27";
  //         value.auth_token = AUTHORIZATION;

  //         const headers = {
  //             "Content-Type": "application/json",
  //             'Authorization': `Bearer ${authToken}`,
  //             "x-api-key": "resetpassword",
  //         };

  //         const body = JSON.stringify(value);
  //         const response = await axios.post('https://dev4.technotackle.in/parliament/public/api/add_user', body, { headers });

  //         console.log(response.data.message);
  //         alert(response.data.message);
  //     } catch (error) {
  //         console.error('Error adding user:', error);
  //         alert('Failed to add user. Please try again later.');
  //     }
  // };

  register("name", {
    required: true,
    validate: (value) => !/\s/.test(value), // Disallow spaces
  });
  const onSubmit = async (data) => {
    await trigger();
    if (Object.keys(errors).length > 0) {
      return;
    }
    try {
      const response = await AddUserAPI(data);
      if (response.success) {
        toast.success(response.message);
        reset();
        // Navigate('/settings');
      } else if (response.parameters.email) {
        // toast.error(response.error);
        toast.error(response.parameters.email[0]);
      } else {
        toast.error(response.parameters.password[0]);
      }
    } catch (error) {}
  };
  const handleSelect = (value) => {
    console.log(value);
    // setValue("role", value);
    // setError("role", {
    //   type: "manual",
    //   message: "Invalid roles",
    // });
    // if (value) {
    //   clearErrors("role");
    // }
  };
  // const role = watch("role");
  // console.log(role);
  // // const handlesumbit = () => {
  // //   const role = watch("role");
  // //   if(role){

  // //   }
  // // };
  return (
    <div className="page_container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="breadcrumb">
              {/* <ul>
                <li className="active">
                  <Link to="#">Home</Link>
                </li>
                <li className="active">
                  <Link to="/admin/settings">Settings</Link>
                </li>
                <li>Add User</li>
              </ul> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="page_title">Add User</div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="profile_card">
              <form className="profile_form" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-6 mb-3">
                    <div className="">
                      <label htmlFor="name" className="form-label">
                        Your Name
                      </label>
                      <input
                        name="name"
                        type="text"
                        className="form-control"
                        onKeyPress={(e) => {
                          const charCode = e.which ? e.which : e.keyCode;
                          if (
                            !(charCode >= 65 && charCode <= 90) && // A-Z
                            !(charCode >= 97 && charCode <= 122) && // a-z
                            charCode !== 8 && // Backspace
                            charCode !== 32 && // Space
                            charCode !== 9
                          ) {
                            // Tab
                            e.preventDefault();
                          }
                        }}
                        id="name"
                        placeholder="Enter Your Name"
                        {...register("name", { required: true })}
                      />
                      {errors.name && (
                        <span className="error-danger">Name is required</span>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-md-6 mb-3">
                    <div className="">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter Email Address"
                        {...register("email", {
                          required: "Please enter Email ID",
                          pattern: {
                            value:
                              /^[a-z][a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i,
                            message: "Please enter a valid Email ID",
                          },
                        })}
                      />
                      {errors.email && (
                        <span className="error-danger">
                          {errors.email.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-md-6 mb-3">
                    <div className="">
                      <label htmlFor="role" className="form-label d-block">
                        Role
                      </label>
                      <select
                        name="role"
                        id=""
                        className="d-block w-100"
                        {...register("role", { required: true })}
                      >
                        <option value="">Select Role</option>
                        <option value="ADMIN">Admin</option>
                        <option value="USER">User</option>
                      </select>
                      {/* <Dropdown
                        name={"role"}
                        options={options}
                        onSelect={handleSelect}
                        register={register} // Pass the register function
                        setValue={setValue} // Pass the setValue function
                        clearErrors={clearErrors} // Pass the clearErrors function
                        errors={errors} // Pass the errors object
                      /> */}
                      {errors.role && (
                        <span className="error-danger">role is required</span>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-md-6 mb-3">
                    <div className="">
                      <label htmlFor="status" className="form-label d-block">
                        Status
                      </label>
                      <select
                        name="status"
                        id=""
                        className="d-block w-100"
                        {...register("status", { required: true })}
                      >
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                      {errors.status && (
                        <span className="error-danger">Status is required</span>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-md-6 mb-3">
                    <div className="">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        placeholder="Enter Password"
                        {...register("password", {
                          required: "Password is required",
                          minLength: {
                            value: 8,
                            message:
                              "Password must be at least 8 characters long",
                          },
                        })}
                      />
                      {errors.password && (
                        <span className="error-danger">
                          {errors.password.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-12 mb-3">
                    <div className="texts-end">
                      <Link
                        to="/admin/settings"
                        className="btn form_cancel-btn me-3"
                      >
                        Cancel
                      </Link>
                      <button type="submit" className="btn form_save-btn">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddUser;
