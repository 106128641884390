// TabsComponent.js
import React from 'react';
import useTabs from './useTabs';
import './index.css'
import tabsData from '../../assets/json/rssfeeds.json'
// import '../dashboard/dashboard.css'
const TabsComponent = (data) => {
  console.log(tabsData);
  
  const { activeTab, changeTab } = useTabs(0); // Initialize with the first tab active

  // const tabsData = [
  //   {
  //     title: "Press Releases",
  //     items: [
  //       {
  //         title: "Media Statement: Basic Education Portfolio Committee Chairperson Saddened by Death of Learners in Mpumalanga",
  //         href: "https://www.parliament.gov.za/press-releases/media-statement-basic-education-portfolio-committee-chairperson-saddened-death-learners-mpumalanga"
  //       },
  //       {
  //         title: "Media Statement: Police Committee Chairperson Addresses Concerns About Increased Incidents of Police Shootings",
  //         href: "https://www.parliament.gov.za/press-releases/media-statement-police-committee-chairperson-addresses-concerns-about-increased-incidents-police-shootings"
  //       },
  //       // ... more items
  //     ]
  //   },
  //   {
  //     title: "Latest News",
  //     items: [
  //       {
  //         title: "Ncop Delegates Support Small Business Development Department’s Plans to Uplift Township and Rural Businesses",
  //         href: "https://www.parliament.gov.za/news/ncop-delegates-support-small-business-development-departments-plans-uplift-township-and-rural-businesses"
  //       },
  //       {
  //         title: "Opposition Parties Call for a Parliamentary Committee to Oversee the Presidency",
  //         href: "https://www.parliament.gov.za/news/opposition-parties-call-parliamentary-committee-oversee-presidency"
  //       },
  //       // ... more items
  //     ]
  //   }
  // ];
  return (
    <div>
    <div className="tabs">
      {tabsData?.tabs.map((tab, index) => (
        <button
          key={index}
          onClick={() => changeTab(index)}
          className={activeTab === index ? 'active' : ''}
        >
          {tab.title}
        </button>
      ))}
    </div>
    <div className="content">
      {tabsData?.tabs[activeTab].items.map((item, index) => (
        <div key={index} className='nav nav-tabs rss_tab mt-0 border-0'>
          <a href={item.href} target="_blank" rel="noopener noreferrer">
            {item.title}
          </a>
        </div>
      ))}
    </div>
  </div>
  );
};

export default TabsComponent;
