// useTabs.js
import { useState } from 'react';

const useTabs = (initialTab = 0) => {
  const [activeTab, setActiveTab] = useState(initialTab);

  const changeTab = (index) => {
    setActiveTab(index);
  };

  return {
    activeTab,
    changeTab,
  };
};

export default useTabs;
