// import React, { useEffect, useState, useRef } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import logo from "../../assets/images/parliament-logo.png";
// import eyeIcon from "../../assets/images/eye.svg";
// import eyeIcon2 from "../../assets/images/slash-eye.svg";
// import "./reset.css";
// import Api from "../Api/Api";
// import { useForm } from "react-hook-form";

// import toast, { Toaster } from "react-hot-toast";
// function ResetPassword() {
//   const {
//     register,
//     formState: { errors },
//     setValue,
//     handleSubmit,
//   } = useForm();
//   const loaderRef = useRef(null);
//   const { Resetpassword } = Api();
//   const [showPassword, setShowPassword] = useState(false);
//   const [showConfirm, setShowConfirm] = useState(false);
//   const [password, setPassword] = useState(null);
//   const [confirm, setConfirm] = useState(null);
//   const [errorMessage, setErrorMessage] = useState("");
//   const [email, setEmail] = useState("");
//   const [token, setToken] = useState("");
//   const navigate = useNavigate();
//   useEffect(() => {
//     // Parse the URL parameters and extract email and auth token
//     const params = new URLSearchParams(window.location.search);
//     const emailParam = params.get("email");
//     const tokenParam = params.get("token");
//     if (emailParam && tokenParam) {
//       localStorage.removeItem("user_id");
//       localStorage.removeItem("user_role");
//       localStorage.removeItem("email_id");
//       localStorage.removeItem("user_name");
//       localStorage.setItem("logged_in", "false");
//       localStorage.removeItem("logged_in");
//       setEmail(emailParam);
//       setToken(tokenParam);
//     }
//   }, []);

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const toggleConfirmVisibility = () => {
//     setShowConfirm(!showConfirm);
//   };

//   const handleConfirmChange = (e) => {
//     setConfirm(e.target.value);
//     if (password && e.target.value !== password) {
//       setErrorMessage("Passwords do not match");
//     } else {
//       setErrorMessage("");
//     }
//   };

//   const onSubmit = async (e) => {
//     // console.log(e.target.value);
//     // console.log({ password, confirm });
//     // Handle form submission
//     let body = {
//       password: password,
//       email: email,
//       token: token,
//     };
//     if (password && email !== "" && confirm && password === confirm) {
//       loaderRef.current = toast.loading("sending...");
//       try {
//         const response = await Resetpassword(body);
//         console.log("login", response);
//         if (response.success) {
//           toast.success(response.message);
//           setConfirm("");
//           setPassword("");
//           toast.dismiss(loaderRef.current);
//           navigate("/Login");
//         }
//         if (response.success === false) {
//           toast.dismiss(loaderRef.current);
//           toast.error(response.parameters.password[0]);
//         }
//       } catch (error) {
//         console.log(error.message);
//         toast.dismiss(loaderRef.current);
//         setConfirm("");
//         setPassword("");
//       }
//     }
//   };

//   const handleResetClick = () => {
//     if (!password || !confirm) {
//       setErrorMessage("Please fill in all fields");
//     } else {
//       setErrorMessage("");
//       // Reset logic here
//     }
//     if (password !== confirm) {
//       setErrorMessage("Passwords do not match");
//       return; // Exit function early if passwords don't match
//     }
//   };

//   return (
//     <div className="main_body">
//       <div className="container">
//         <div className="row justify-content-center">
//           <div className="col-12 col-sm-6">
//             <div className="form_container">
//               <div className="login_logo mb-3 text-center">
//                 <img src={logo} alt="logo" />
//               </div>
//               <div className="login_form-inner">
//                 <div className="login_title">Reset Password</div>
//                 <div className="login_sm-title">
//                   Enter New Password to reset your Password
//                 </div>
//                 <form onSubmit={handleSubmit(onSubmit)}>
//                   <div className="input_from position-relative">
//                     <input
//                       type={showPassword ? "text" : "password"}
//                       placeholder="Enter Password"
//                       value={password}
//                       onChange={(e) => setPassword(e.target.value)}
//                       // maxLength={6}
//                       {...register("password", {
//                         required: "Password is required",
//                         minLength: {
//                           value: 8,
//                           message:
//                             "Password must be at least 8 characters long",
//                         },
//                       })}
//                       // pattern="\d{6}"
//                     />
//                     {errors.password && (
//                       <span className="error_message">
//                         {errors.password.message}
//                       </span>
//                     )}
//                     <span
//                       className="text_visible"
//                       onClick={togglePasswordVisibility}
//                     >
//                       {showPassword ? (
//                         <img src={eyeIcon} alt="hide" />
//                       ) : (
//                         <img src={eyeIcon2} alt="show" />
//                       )}
//                     </span>
//                   </div>
//                   <div className="input_from position-relative">
//                     <input
//                       type={showConfirm ? "text" : "password"}
//                       placeholder="Confirm New Password"
//                       value={confirm}
//                       onChange={handleConfirmChange}
//                       {...register("confirmpsw", {
//                         required: "Password is required",
//                         minLength: {
//                           value: 8,
//                           message:
//                             "Password must be at least 8 characters long",
//                         },
//                       })}
//                       // maxLength={6}
//                       // pattern="\d{6}"
//                     />
//                     {errors.confirmpsw && (
//                       <span className="error_message">
//                         {errors.confirmpsw.message}
//                       </span>
//                     )}
//                     <span
//                       className="text_visible"
//                       onClick={toggleConfirmVisibility}
//                     >
//                       {showConfirm ? (
//                         <img src={eyeIcon} alt="hide" />
//                       ) : (
//                         <img src={eyeIcon2} alt="show" />
//                       )}
//                     </span>
//                   </div>
//                   {errorMessage && (
//                     <div className="error-message">{errorMessage}</div>
//                   )}
//                   <div>
//                     <button
//                       className="login_submit btn w-100 text-white"
//                       type="submit"
//                       onClick={handleResetClick}
//                     >
//                       Reset Password
//                     </button>
//                   </div>
//                 </form>

//                 <div className="google_btn">
//                   <div className="have_login text-center my-4">
//                     Already have an Account ? <Link to="/login">Login</Link>
//                   </div>
//                 </div>
//               </div>

//               <div className="copy_right text-center t_small_none">
//                 Copyright 2024. All Rights Reserved
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ResetPassword;

import React, { useEffect, useRef, useState } from "react";
import logo from "../../assets/images/parliament-logo.png";
import eyeIcon from "../../assets/images/eye.svg";
import eyeIcon2 from "../../assets/images/slash-eye.svg";
import "./reset.css";
import Api from "../Api/Api";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
const PasswordReset = () => {
  const { Resetpassword } = Api();
  const loaderRef = useRef(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({ mode: "all" }); // Validate on change and blur
  const navigate = useNavigate();
  const password = watch("password");
  const confirmPassword = watch("confirmPassword");

  const onSubmit = async (data) => {
    if (password !== confirmPassword) {
      toast.error("The password and confirmation password do not match.");
      return; // Prevent form submission if passwords don't match
    }
    console.log(data);
    loaderRef.current = toast.loading("sending...");
    let body = {
      password: data.password,
      email: email,
      token: token,
    };
    try {
      const response = await Resetpassword(body);

      if (response.success) {
        toast.success(response.message);
        toast.dismiss(loaderRef.current);
        navigate("/Login");
      }
      if (response.success === false) {
        toast.error(response.message);
        toast.error(response.parameters.password[0]);
        toast.dismiss(loaderRef.current);
      }
    } catch (error) {
      console.log(error.message);
      toast.dismiss(loaderRef.current);
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmVisibility = () => {
    setShowConfirm(!showConfirm);
  };

  useEffect(() => {
    // Parse the URL parameters and extract email and auth token
    const params = new URLSearchParams(window.location.search);
    const emailParam = params.get("email");
    const tokenParam = params.get("token");
    if (emailParam && tokenParam) {
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_role");
      localStorage.removeItem("email_id");
      localStorage.removeItem("user_name");
      localStorage.setItem("logged_in", "false");
      localStorage.removeItem("logged_in");
      setEmail(emailParam);
      setToken(tokenParam);
    }
  }, []);
  return (
    <div className="main_body">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-6">
            <div className="form_container">
              <div className="login_logo mb-3 text-center">
                <img src={logo} alt="logo" />
              </div>
              <div className="login_form-inner">
                <div className="login_title">Reset Password</div>
                <div className="login_sm-title">
                  Enter New Password to reset your Password
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="input_from position-relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter Password"
                      name="password"
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message:
                            "Password must be at least 8 characters long",
                        },
                      })}
                      onKeyDown={(e) => e.key === " " && e.preventDefault()}
                    />
                    {errors.password && (
                      <span className="error_message">
                        {errors.password.message}
                      </span>
                    )}
                    <span
                      className="text_visible"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <img src={eyeIcon} alt="hide" />
                      ) : (
                        <img src={eyeIcon2} alt="show" />
                      )}
                    </span>
                  </div>
                  <div className="input_from position-relative">
                    <input
                      type={showConfirm ? "text" : "password"}
                      placeholder="Confirm New Password"
                      name="confirmPassword"
                      {...register("confirmPassword", {
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message:
                            "Password must be at least 8 characters long",
                        },
                      })}
                      onKeyDown={(e) => e.key === " " && e.preventDefault()}
                    />
                    {errors.confirmPassword && (
                      <span className="error_message">
                        {errors.confirmPassword.message}
                      </span>
                    )}
                    <span
                      className="text_visible"
                      onClick={toggleConfirmVisibility}
                    >
                      {showConfirm ? (
                        <img src={eyeIcon} alt="hide" />
                      ) : (
                        <img src={eyeIcon2} alt="show" />
                      )}
                    </span>
                  </div>

                  <div>
                    <button
                      className="login_submit btn w-100 text-white"
                      type="submit"
                      // onClick={handleResetClick}
                    >
                      Reset Password
                    </button>
                  </div>
                </form>

                <div className="google_btn">
                  <div className="have_login text-center my-4">
                    Already have an Account ? <Link to="/login">Login</Link>
                  </div>
                </div>
              </div>

              <div className="copy_right text-center t_small_none">
                Copyright 2024. All Rights Reserved
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
