import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import Api from "../Api/Api";
function PolarAreaChart() {
  const { click_count_percentage } = Api();

  const [TrendingData, setTrendingData] = useState([]);

  const GetTrendingData = async () => {
    try {
      let data = await click_count_percentage();
      console.log("TrendingTopics", data);
      setTrendingData(data.parameters);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    GetTrendingData();
  }, []);

  return (
    <div>
      {TrendingData && TrendingData.length > 0 && (
        <Pie
          data={{
            labels: TrendingData.map((meet, index) => [meet.channel]),
            datasets: [
              {
                label: "My First Dataset",
                boxWidth: 20,

                // data: [30, 15, 35, 20],

                data: TrendingData.map((meet, index) => [meet.percentage]),
                backgroundColor: ["#D22B2B", "#0000FF", "#00000", "#FA00FF"],
                borderColor: ["#D22B2B", "#0000FF", "#00000", "#FA00FF"],
                borderWidth: 1,
              },
            ],
          }}
          options={{
            plugins: {
              datalabels: {
                display: true,
                color: "#fff",
                formatter: (value, context) => {
                  return `${value}%`;
                },
                anchor: "end",
                align: "start",
              },
            },
            legend: {
              display: true,
              position: "bottom",
            },
            scale: {
              ticks: { beginAtZero: true },
            },
          }}
          plugins={[ChartDataLabels]}
        />
      )}
    </div>
  );
}

export default PolarAreaChart;
