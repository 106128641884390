import React from 'react';
import './skeleton-product.css'; // Import the CSS file

const SkeletonLoader = () => {
  return (
  <div className='row'>
     <div className="col-lg-4">
     <div className="skeleton-product-card">
      <div className="skeleton-image"></div>
      <div className="skeleton-content">
        <div className="skeleton-title"></div>
        <div className="skeleton-details">
          <div className="skeleton-text"></div>
          <div className="skeleton-text short"></div>
        </div>
        <div className="skeleton-footer">
          <div className="skeleton-status"></div>
          <div className="skeleton-icons">
            <div className="skeleton-icon"></div>
            <div className="skeleton-icon"></div>
            <div className="skeleton-icon"></div>
          </div>
        </div>
      </div>
    </div>
     </div>
     <div className="col-lg-4">
     <div className="skeleton-product-card">
      <div className="skeleton-image"></div>
      <div className="skeleton-content">
        <div className="skeleton-title"></div>
        <div className="skeleton-details">
          <div className="skeleton-text"></div>
          <div className="skeleton-text short"></div>
        </div>
        <div className="skeleton-footer">
          <div className="skeleton-status"></div>
          <div className="skeleton-icons">
            <div className="skeleton-icon"></div>
            <div className="skeleton-icon"></div>
            <div className="skeleton-icon"></div>
          </div>
        </div>
      </div>
    </div>
     </div>
     <div className="col-lg-4">
     <div className="skeleton-product-card">
      <div className="skeleton-image"></div>
      <div className="skeleton-content">
        <div className="skeleton-title"></div>
        <div className="skeleton-details">
          <div className="skeleton-text"></div>
          <div className="skeleton-text short"></div>
        </div>
        <div className="skeleton-footer">
          <div className="skeleton-status"></div>
          <div className="skeleton-icons">
            <div className="skeleton-icon"></div>
            <div className="skeleton-icon"></div>
            <div className="skeleton-icon"></div>
          </div>
        </div>
      </div>
    </div>
     </div>
  </div>
  );
};

export default SkeletonLoader;
