import React, { useEffect, useState, useRef } from "react";
import { Doughnut } from "react-chartjs-2";
import ReportList from "./ReportList";
import Api from "../Api/Api";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
function ReportChart() {
  const { SessionsCount, User_report } = Api();
  const [sessionsData, setSessionsData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const BarChartRef = useRef(null);
  const [barChartHeight, setBarChartHeight] = useState(0);

  const handleFilterChange = async () => {
    try {
      const data = await User_report(selectedFilter);
      setSessionsData(data.parameters);
      console.log(data.parameters);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleFilterChange();
  }, [selectedFilter]);

  useEffect(() => {
    // Set BarChart height after component mounts
    if (BarChartRef.current) {
      const height = BarChartRef.current.clientHeight;
      setBarChartHeight(height);
      console.log("sadasd", height);
    }
  }, [sessionsData]);

  return (
    <>
      <div className="col-12 col-sm-12 col-md-6 mb-3">
        <div className="chart_card bg-white p-3 " ref={BarChartRef}>
          <h3>Reported Meetings</h3>
          <div>
            {sessionsData && sessionsData.length > 0 && (
              <Doughnut
                className="reported_meeting-chart"
                // style={{margin:"0 auto"}}
                data={{
                  // labels: [
                  //   // "Total Meetings",
                  //   // "Streamed Meetings",
                  //   // "Remaining Meetings",
                  //   sessionsData.video_title,
                  // ],
                  labels: sessionsData
                    .slice(0, 5)
                    .map((session) => session.video_title),
                  datasets: [
                    {
                      data: sessionsData
                        .slice(0, 5)
                        .map((meet, index) => [meet.percentage]),
                      backgroundColor: [
                        "#002F5D",
                        "#23511E",
                        "#b0b0eb",
                        "#009efa",
                        "#C58C00",
                      ],
                      borderColor: [
                        "#002F5D",
                        "#23511E",
                        "#b0b0eb",
                        "#009efa",
                        "#C58C00",
                      ],
                      aspectRatio: 4 / 3,
                    },
                  ],
                }}
              />
            )}
          </div>
        </div>
      </div>

      <div className="col-12 col-sm-12 col-md-6 h-100">
        <div className="chart_card bg-white h-100">
          {/* <h3 className="p-3">Reported Meetings</h3> */}
          <h3 className="p-3"></h3>

          <div className="glitch_list">
            <ReportList barChartHeight={barChartHeight} />
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportChart;
