import React, { createContext, useContext, useState } from "react";

const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [isProfile, setIsProfile] = useState(false);
  const [pressDetails, setPressdetails] = useState([]);
  const toggleProfile = () => {
    setIsProfile((prevIsProfile) => !prevIsProfile);
    console.log(isProfile);
  };

  return (
    <ProfileContext.Provider value={{ isProfile, toggleProfile , setPressdetails , pressDetails}}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => {
  return useContext(ProfileContext);
};
