import React, { useState, useEffect } from "react";

function Otptimer({ expiryTime, setResend }) {
  const [seconds, setSeconds] = useState(
    Math.floor((expiryTime - Date.now()) / 1000)
  );

  useEffect(() => {
    const timer = setInterval(() => {
      const remainingSeconds = Math.floor((expiryTime - Date.now()) / 1000);
      setSeconds(remainingSeconds);
    }, 1000);

    return () => clearInterval(timer);
  }, [expiryTime]);
  useEffect(() => {
    if (seconds <= 0) {
      setResend(false);
    }
  }, [seconds]);

  return <div className="text-danger">{seconds > 0 ? `Resend in ${seconds} seconds` : ""}</div>;
}

export default Otptimer;
