import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/login/Login";
import Register from "./components/register/Register";
import ForgotPassword from "./components/forgotpassword/ForgotPassword";
import Sidemenu from "./components/sidemenu/Sidemenu";
import Dashboard from "./components/dashboard/Dashboard";
import { useEffect, useState } from "react";
import Header from "./components/header/Header";
import Meetings from "./components/meetings/Meetings";
import MeetingDetail from "./components/meetings/meetingDetail";
import "./App.css";
import VideoBlog from "./components/videos/VideoBlog";
import MyProfile from "./components/profile/MyProfile";
import LiveVideos from "./components/LiveVideos/LiveVideos";
import Settings from "./components/Settings/Settings";
import AddUser from "./components/Settings/AddUser";
import EditUser from "./components/Settings/EditUser";
import LikedVideos from "./components/LikedVideos/LikedVideos";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import toast, { Toaster } from "react-hot-toast";
import LikedMeetings from "./components/LikedMeetings/LikedMeetings";
import FavouriteMeetings from "./components/FavouriteMeetings/FavouriteMeetings";
import Reports from "./components/Reports/Reports";
import OTP from "./components/OTP/OTP";
import { ProfileProvider } from "../src/components/provider/ProfileProvider";
import PressPage from "./components/PressPage/index";
import PressDetails from "../src/components/PressPage/pressDetails";
import Allmeeting from "../src/components/Allmeetings/index";
function App() {
  const storedValue = localStorage.getItem("logged_in");
  const [logged_in, setlogged_in] = useState(storedValue);
  const user_role = localStorage.getItem("user_role");
  console.log(user_role);

  useEffect(() => {
    setlogged_in(storedValue === "true");
  }, [storedValue]);

  return (
    <div>
      <ProfileProvider>
        <BrowserRouter>
          {logged_in && <Sidemenu setlogged_in={setlogged_in} />}
          {logged_in && <Header setlogged_in={setlogged_in} />}
          <Routes>
            {user_role === "USER" && (
              <Route
                path="/"
                element={
                  !logged_in ? (
                    <Login setlogged_in={setlogged_in} />
                  ) : (
                    <Navigate to="/user/livevideos" />
                  )
                }
              />
            )}
            {user_role === "ADMIN" && (
              <Route
                path="/"
                element={
                  !logged_in ? (
                    <Login setlogged_in={setlogged_in} />
                  ) : (
                    <Navigate to="/admin/dashboard" />
                  )
                }
              />
            )}
            {!logged_in && (
              <Route
                path="/"
                element={!logged_in && <Login setlogged_in={setlogged_in} />}
              />
            )}
            {!logged_in && (
              <Route
                path="/Login"
                element={!logged_in && <Login setlogged_in={setlogged_in} />}
              />
            )}
            {/* <Route
            path="/login"
            element={
              !logged_in ? (
                <Login setlogged_in={setlogged_in} />
              ) : (
                <Navigate to="/user/meetings" />
              )
            }
          /> */}

            <Route path="/otp" element={<OTP />} />
            <Route path="/register" element={<Register />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/admin/dashboard"
              element={
                logged_in && user_role === "ADMIN" ? (
                  <Dashboard />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/admin/meetings"
              element={
                logged_in && user_role === "ADMIN" ? (
                  <Meetings />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/user/meetings"
              element={
                logged_in && user_role === "USER" ? (
                  <Meetings />
                ) : (
                  <Navigate to="/" />
                )
              }
            />

            <Route
              path="/admin/likedmeetings/meeting-detail/:videoId"
              element={logged_in ? <MeetingDetail /> : <Navigate to="/" />}
            />
            <Route
              path="/admin/subcribedmeeeting/meeting-detail/:videoId"
              element={logged_in ? <MeetingDetail /> : <Navigate to="/" />}
            />

            <Route
              path="/admin/reports/meeting-detail/:videoId"
              element={logged_in ? <MeetingDetail /> : <Navigate to="/" />}
            />
             <Route
              path="/admin/all-meeting/meeting-detail/:videoId"
              element={logged_in ? <MeetingDetail /> : <Navigate to="/" />}
            />
            <Route
              path="/user/livevideos/meeting-detail/:videoId"
              element={
                logged_in && user_role === "USER" ? (
                  <MeetingDetail />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/user/videos/meeting-detail/:videoId"
              element={
                logged_in && user_role === "USER" ? (
                  <MeetingDetail />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/user/likedvideos/meeting-detail/:videoId"
              element={
                logged_in && user_role === "USER" ? (
                  <MeetingDetail />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/admin/meetings/meeting-detail/:videoId"
              element={
                logged_in && user_role === "ADMIN" ? (
                  <MeetingDetail />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/user/meetings/meeting-detail/:videoId"
              element={
                logged_in && user_role === "USER" ? (
                  <MeetingDetail />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/user/videos"
              element={
                logged_in && user_role === "USER" ? (
                  <VideoBlog />
                ) : (
                  <Navigate to="/" />
                )
              }
            />

            <Route
              path="/admin/my-profile"
              element={
                logged_in && user_role === "ADMIN" ? (
                  <MyProfile />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/user/my-profile"
              element={
                logged_in && user_role === "USER" ? (
                  <MyProfile />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/user/livevideos"
              element={
                logged_in && user_role === "USER" ? (
                  <LiveVideos />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/admin/settings"
              element={
                logged_in && user_role === "ADMIN" ? (
                  <Settings />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/admin/settings/adduser"
              element={
                logged_in && user_role === "ADMIN" ? (
                  <AddUser />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/user/adduser"
              element={
                logged_in && user_role === "USER" ? (
                  <AddUser />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/admin/settings/edituser/:user_id"
              element={
                logged_in && user_role === "ADMIN" ? (
                  <EditUser />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/user/likedvideos"
              element={
                logged_in && user_role === "USER" ? (
                  <LikedVideos />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/user/press-release"
              element={
                logged_in && user_role === "USER" ? (
                  <PressPage />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            {/* <Route
              path="/admin/press-release"
              element={
                logged_in && user_role === "ADMIN" ? (
                  <PressPage />
                ) : (
                  <Navigate to="/" />
                )
              }
            /> */}
            {/* <Route
              path="/admin/all-meetings"
              element={
                logged_in && user_role === "ADMIN" ? (
                  <Allmeeting />
                ) : (
                  <Navigate to="/" />
                )
              }
            /> */}
            {/* <Route path="/press-releases/:slug" element={<PressDetails />} /> */}
            <Route
              path="/admin/likedmeetings"
              element={
                logged_in && user_role === "ADMIN" ? (
                  <LikedMeetings />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/admin/subcribedmeeeting"
              element={
                logged_in && user_role === "ADMIN" ? (
                  <FavouriteMeetings />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/admin/reports"
              element={
                logged_in && user_role === "ADMIN" ? (
                  <Reports />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
          </Routes>
        </BrowserRouter>

        {/* <ToastContainer /> */}
        <Toaster position="top-center" reverseOrder={false} />
      </ProfileProvider>
    </div>
  );
}

export default App;
