import React, { useEffect, useState, useRef } from "react";
import { Line } from "react-chartjs-2";
import Api from "../Api/Api";
function WaveLineChart() {
  const { SessionsCount } = Api();
  const [SessionsData, setSessionsData] = useState([]);
  console.log(SessionsData);
  const [selectedFilter, setSelectedFilter] = useState("");
  const BarChartRef = useRef(null);
  // const [barChartHeight, setBarChartHeight] = useState(0);

  const handleFilterChange = async () => {
    try {
      const data = await SessionsCount(selectedFilter);
      setSessionsData(data.parameters.meetings);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleFilterChange();
  }, [selectedFilter]);

  return (
    <>
      <div className="col-12 col-sm-12 col-md-12 mb-3">
        <div className="chart_card bg-white p-3" ref={BarChartRef}>
          <h3>Meetings</h3>
          <div>
            {SessionsData && SessionsData.length > 0 && (
              <Line
                data={{
                  labels: SessionsData.map((meet, index) => [meet.month]),

                  datasets: [
                    {
                      label: "Streamed",
                      data: SessionsData.map((meet) =>
                        meet.streamed_count !== null ? meet.streamed_count : 0
                      ),
                      fill: false,
                      borderColor: "#265936",
                      tension: 0.46,
                      backgroundColor: "#265936",
                    },
                    // {
                    //   label: "Missed",
                    //   data: SessionsData.map((meet) =>
                    //     meet.remaining_count !== null ? meet.remaining_count : 0
                    //   ),
                    //   fill: false,
                    //   borderColor: "#F44336",
                    //   tension: 0.46,
                    //   backgroundColor: "#F44336",
                    // },
                    // {
                    //   label: "Scheduled",
                    //   data: SessionsData.map((meet) =>
                    //     meet.total_count !== null ? meet.total_count : 0
                    //   ),
                    //   fill: false,
                    //   borderColor: "#9E7C0C",
                    //   backgroundColor: "#9E7C0C",
                    //   tension: 0.46,
                    // },

                    // {
                    //   label: "Total Meetings",
                    //   data: SessionsData.map((meet) =>
                    //     meet.total_count !== null ? meet.total_count : 0
                    //   ),
                    //   fill: false,
                    //   borderColor: "#2D9CDB",
                    //   tension: 0.46,
                    // },
                    // {
                    //   label: "Streamed Meetings",
                    //   data: SessionsData.map((meet) =>
                    //     meet.streamed_count !== null ? meet.streamed_count : 0
                    //   ),
                    //   fill: false,
                    //   borderColor: "#265936",
                    //   tension: 0.46,
                    // },
                    // {
                    //   label: "Remaining Meetings",
                    //   data: SessionsData.map((meet) =>
                    //     meet.remaining_count !== null ? meet.remaining_count : 0
                    //   ),
                    //   fill: false,
                    //   borderColor: "#9E7C0C",
                    //   tension: 0.46,
                    // },
                  ],
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default WaveLineChart;
