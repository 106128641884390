// SkeletonLoader.js
import React from 'react';
import './SkeletonLoader.css';

const SkeletonLoader = () => {
  return (
    <div className="skeleton-container">
      <div className="skeleton-previous-card "></div>
      <div className="skeleton-previous-card "></div>
      <div className="skeleton-previous-card "></div>
      <div className="skeleton-previous-card "></div>
      <div className="skeleton-previous-card "></div>
      {/* <div className="skeleton-previous-card "></div> */}
    </div>
  );
};

export default SkeletonLoader;
