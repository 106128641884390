import React, { useState, useEffect, useRef } from "react";
import Api from "../Api/Api";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { CiShare1 } from "react-icons/ci";

function ReportList({ barChartHeight }) {
  const { DashboardReportList } = Api();
  const moment = require("moment");
  const [ReportData, setReportData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPageCount, setPerPageCount] = useState("");
  const rowsPerPage = 10;
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = Math.max(indexOfLastRow - rowsPerPage, 0);

  const currentRows = ReportData.slice(indexOfFirstRow, indexOfLastRow);
  const dataTableRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [TotalCount, setTotalCount] = useState("");

  const handleNextPage = async () => {
    setCurrentPage(currentPage + 1);
    if (currentPage >= 1) {
      GetReportList({
        page: currentPage + 1,
      });
    }
    if (window.innerWidth > 768) {
      // Adjust 768 according to your design
      window.scrollTo(0, 0);
    }
  };

  const handlePrevPage = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    if (currentPage > 1) {
      GetReportList({
        page: currentPage - 1,
      });
    }
    if (window.innerWidth > 768) {
      // Adjust 768 according to your design
      window.scrollTo(0, 0);
    }
  };

  const GetReportList = async (value) => {
    console.log(value);
    try {
      let data = await DashboardReportList(value);
      setLoading(true);
      setReportData(data.parameters.data);
      setTotalCount(data.parameters.total);
      setPerPageCount(data.parameters.to);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    // GetMeetingList();
    GetReportList({
      page: 1,
    });
  }, []);

  return (
    <div
      className="report_card"
      style={{ height: `calc(${barChartHeight}px - 60px)` }}
    >
      {/* <ul className='report_data-list p-0 m-0' style={{ height: `${barChartHeight}px` }}>
                {ReportData && ReportData.length ? (
                    ReportData.map((meet, index) => (
                        <li key={index.id}>
                            <Link to="#" className='d-flex align-items-center justify-content-between'>
                                <span>{index + 1}. {meet.video_title}</span>
                                <span>
                                    {meet.total_reports}
                                </span>
                            </Link>
                        </li>
                    ))
                ) : (
                    <li>
                        <span colSpan="3" className="text-center">No data</span>
                    </li>
                )}

            </ul> */}

      <div className="table-responsive main_datatable">
        <table
          ref={dataTableRef}
          className="table custom_datatable"
          style={{ width: "100%" }}
        >
          <thead>
            <tr>
              <th>S.NO</th>
              <th>TITLE</th>
              <th className="text-center">COUNT</th>
            </tr>
          </thead>

          {loading ? (
            // Loader component or loading indicator
            <tbody>
              <tr>
                <td colSpan="3" className="text-center">
                  Loading...
                </td>
              </tr>
            </tbody>
          ) : (
            // Your regular UI content
            <tbody>
              {ReportData && ReportData.length ? (
                ReportData.map((meet, index) => (
                  <tr key={meet.id}>
                    <td className="text-center">
                      {indexOfFirstRow + index + 1}
                    </td>
                    <td>
                      {meet.video_title}
                      <br />
                      <small>
                        <div className="d-flex justify-content-between">
                          {" "}
                          <span>{meet.video_date}</span>{" "}
                          <span>
                            {" "}
                            {meet.from_time
                              ? moment(meet.from_time, "HH:mm:ss").format(
                                  "HH:mm"
                                )
                              : "00:00"}
                            -
                            {meet.to_time
                              ? moment(meet.to_time, "HH:mm:ss").format("HH:mm")
                              : "00:00"}
                          </span>
                        </div>
                      </small>
                    </td>
                    <td className="text-center">{meet.total_reports}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">
                    No data
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
      <div className="d-flex align-items-center justify-content-between px-3 pb-3">
        <p className="m-0 table_page-count">
          Showing {indexOfFirstRow + 1}-{PerPageCount} of {TotalCount}
        </p>
        <div className="d-flex align-items-center justify-content-end table_nav">
          <button disabled={currentPage === 1} onClick={handlePrevPage}>
            <FaAngleLeft />
          </button>
          <button
            // disabled={indexOfLastRow >= ReportData.length}
            disabled={ReportData.length < 9 || ReportData.length === 0}
            onClick={handleNextPage}
          >
            <FaAngleRight />
          </button>
        </div>
      </div>
    </div>
  );
}

export default ReportList;
