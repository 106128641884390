import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import "react-datepicker/dist/react-datepicker.css";

export default function UseDatepicker({
  handleDate,
  selecteddate,
  placeholderText,
  resetDatePicker,
  minDate,
  maxDate,
  formcontrol,
  defaultvalue,
}) {
  // const datePickerRef = useRef(null);
  const moment = require("moment");

  const defaultMoment = moment(
    selecteddate ? selecteddate : defaultvalue,
    "DD/MM/YYYY"
  );

  const [startDate, setStartDate] = useState(null);

  useEffect(() => {
    setStartDate(selecteddate);
  }, [selecteddate]);
  const currentYear = defaultvalue
    ? startDate.getFullYear()
    : getYear(new Date());
  const currentMonth = defaultvalue
    ? startDate.getMonth()
    : getMonth(new Date());
  const years = range(1940, currentYear + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    if (selecteddate) {
      const selectedDateObj = moment(selecteddate, "DD/MM/YYYY");
      if (selectedDateObj.isValid()) {
        setStartDate(selectedDateObj.toDate());
      }
    }
  }, [selecteddate]);

  return (
    <DatePicker
      className={maxDate ? "custom-input-style-selected" : "custom-input-style"}
      placeholderText={placeholderText}
      dateFormat="dd/MM/yyyy"
      minDate={minDate}
      maxDate={maxDate}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
          <button
            onClick={(e) => {
              e.preventDefault();
              decreaseMonth();
            }}
            disabled={prevMonthButtonDisabled}
          >
            {"<"}
          </button>
          <select
            className="custom-select-style"
            value={getYear(date)}
            onChange={({ target: { value } }) => {
              changeYear(value);
              const newDate = new Date(date);
              newDate.setFullYear(value);
              setStartDate(newDate);
              handleDate(newDate);
            }}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            className="custom-select-style "
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) => {
              changeMonth(months.indexOf(value));
              const newDate = new Date(date);
              newDate.setMonth(months.indexOf(value));
              setStartDate(newDate);
              handleDate(newDate);
            }}
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button
            onClick={(e) => {
              e.preventDefault();
              increaseMonth();
            }}
            disabled={nextMonthButtonDisabled}
          >
            {">"}
          </button>
        </div>
      )}
      selected={startDate ? startDate : ""}
      onChange={(date) => {
        setStartDate(date);
        handleDate(date);
      }}
    />
  );
}
