// import React, { useEffect, useState, useRef } from "react";
// import { Chart as Charts } from "chart.js/auto";
// import { Bar, Doughnut, Line } from "react-chartjs-2";
// import Api from "../Api/Api";
// import ReportList from "./ReportList";
// import RSSFeed from "./RSSFeed";
// import "react-datepicker/dist/react-datepicker.css";
// import Chart from "chart.js/auto";
// import "chartjs-adapter-moment";
// import Datepickercomponent from "../util/Datepicker";
// function BarChart() {
//   const { SessionsCount } = Api();
//   const moment = require("moment");
//   const [SessionsData, setSessionsData] = useState([]);
//   const [selectedFilter, setSelectedFilter] = useState("");
//   const [loading, setLoading] = useState(false);
//   const BarChartRef = useRef(null);
//   const [barChartHeight, setBarChartHeight] = useState(0);
//   const [selectfromdate, setSelectedDate] = useState(null);
//   const [selecttodate, setselectTodate] = useState(null);
//   const [filter, setFilter] = useState(false);
//   const [totalcount, setTotalcount] = useState(null);

//   const handleFilterChange = async ({ from_date, to_date }) => {
//     if (selectedFilter == "custom") {
//       setFilter(true);
//       try {
//         setLoading(true);
//         let body = {
//           selectedFilter: selectedFilter,
//           from_date: from_date ? moment(from_date).format("DD/MM/YYYY") : "",
//           to_date: to_date ? moment(to_date).format("DD/MM/YYYY") : "",
//         };
//         const data = await SessionsCount(body);
//         let filteredData = data.parameters.meetings.filter((meet) => {
//           const meetDate = moment(meet.day, "YYYY-MM-DD");
//           return (
//             (!from_date || meetDate.isSameOrAfter(moment(from_date))) &&
//             (!to_date || meetDate.isSameOrBefore(moment(to_date)))
//           );
//         });

//         setSessionsData(filteredData);

//         setTotalcount(data.parameters.sessionCount.totalCount);
//         // setSessionsData(data.parameters.meetings);
//         setLoading(false);
//       } catch (err) {
//         setLoading(false);
//         console.log(err);
//       }
//     }
//     if (selectedFilter !== "custom") {
//       setFilter(false);
//       setSelectedDate(null);
//       setselectTodate(null);
//       try {
//         setLoading(true);

//         let body = {
//           selectedFilter: selectedFilter,
//           from_date: "",
//           to_date: "",
//         };
//         const data = await SessionsCount(body);

//         // let filteredData = data.parameters.meetings.filter((meet) => {
//         //   const meetDate = moment(meet.day, "YYYY-MM-DD");
//         //   return (
//         //     (!from_date || meetDate.isSameOrAfter(moment(from_date))) &&
//         //     (!to_date || meetDate.isSameOrBefore(moment(to_date)))
//         //   );
//         // });
//         // console.log(filteredData);
//         // setSessionsData(data);
//         setSessionsData(data.parameters.meetings);

//         setTotalcount(data.parameters.sessionCount.totalCount);

//         setLoading(false);
//       } catch (err) {
//         setLoading(false);
//         console.log(err);
//       }
//     }
//   };

//   useEffect(() => {
//     handleFilterChange({
//       from_date: selectfromdate,
//       to_date: selecttodate,
//       selectedFilter: selectedFilter,
//     });
//   }, [selectedFilter]);

//   const getLabel = (meet) => {
//     switch (selectedFilter) {
//       case "daily":
//         return meet.day;
//       case "weekly":
//         return meet.day;
//       case "monthly":
//         return meet.month;
//       case "yearly":
//         return meet.year;
//       default:
//         return meet.month;
//     }
//   };

//   useEffect(() => {
//     // Set BarChart height after component mounts
//     if (BarChartRef.current) {
//       const height = BarChartRef.current.clientHeight;
//       setBarChartHeight(height);
//     }
//   }, [SessionsData]);

//   const handleDateChange = (date) => {
//     if (date) {
//       // setSelectedDate(null);
//       const formatdate = moment(date).format("DD/MM/YYYY");
//       setSelectedDate(date);
//       handleFilterChange({ from_date: date, to_date: selecttodate });
//     }
//   };

//   const handleTodate = (date) => {
//     // const formatdate = moment(date).format("DD/MM/YY");
//     setselectTodate(date);
//     handleFilterChange({ from_date: selectfromdate, to_date: date });
//   };

//   return (
//     <>
//       <div className="col-12 col-sm-12 col-md-8 mb-3">
//         <div
//           className="chart_card bg-white p-3 position-relative bar_chart"
//           ref={BarChartRef}
//         >
//           <div className="d-flex align-items-center py-2">
//             <div>
//               <h3 className="mb-0">Sessions Count</h3>
//               <small className="fw-bold">
//                 {" "}
//                 Total Meetings count : {totalcount}
//               </small>
//             </div>
//             {filter && (
//               <>
//                 <div className="from_date-filter filter_col date_picker px-4">
//                   <Datepickercomponent
//                     selecteddate={selectfromdate}
//                     handleDate={handleDateChange}
//                     placeholderText="From Date"
//                   />
//                 </div>
//                 <div className="to_date-filter filter_col date_picker">
//                   <Datepickercomponent
//                     selecteddate={selecttodate}
//                     handleDate={handleTodate}
//                     placeholderText="To Date"
//                   />
//                 </div>
//               </>
//             )}
//           </div>
//           <div>
//             <div className="profile_form session_count-dropdown">
//               <select
//                 name="status"
//                 id="status"
//                 value={selectedFilter}
//                 onChange={(e) => setSelectedFilter(e.target.value)}
//               >
//                 <option value="all">All</option>
//                 <option value="daily">Day</option>
//                 <option value="weekly">Week</option>
//                 <option value="monthly">Month</option>
//                 <option value="yearly">Year</option>
//                 <option value="custom">custom</option>
//               </select>
//             </div>
//             <div>
//               {selectedFilter == "custom" && (
//                 <div>
//                   {loading ? (
//                     <p className="text-center">Loading...</p>
//                   ) : SessionsData && SessionsData.length > 0 ? (
//                     <Bar
//                       data={{
//                         // labels: SessionsData.map((meet) => getLabel(meet)),
//                         labels: SessionsData.map((meet) =>
//                           meet.day
//                             ? moment(meet.day).format("YYYY-MM-DD")
//                             : getLabel(meet)
//                         ),
//                         datasets: [
//                           {
//                             label: "Streamed",
//                             data: SessionsData.map((meet) =>
//                               meet.streamed_count !== null
//                                 ? meet.streamed_count
//                                 : 0
//                             ),
//                             backgroundColor: "#265936",
//                             borderRadius: 50,
//                           },
//                         ],
//                       }}
//                       options={{
//                         responsive: true,
//                         scales: {
//                           // x: {
//                           //   type: "category",

//                           //   display: true,
//                           // },
//                           x: {
//                             type: "time",
//                             time: {
//                               unit: "day",
//                               displayFormats: {
//                                 day: "MMM D, YYYY", // Custom date format for display
//                               },
//                             },
//                             display: true,
//                           },
//                         },
//                       }}
//                     />
//                   ) : (
//                     <div class="alert alert-danger w-50">
//                       No data available.
//                     </div>
//                   )}
//                 </div>
//               )}
//               {selectedFilter !== "custom" && (
//                 <div>
//                   {loading ? (
//                     <p className="text-center">Loading...</p>
//                   ) : SessionsData && SessionsData.length > 0 ? (
//                     <Bar
//                       data={{
//                         // labels: SessionsData.map((meet) => getLabel(meet)),
//                         labels: SessionsData.map((meet) =>
//                           meet.day
//                             ? moment(meet.day).format("YYYY-MM-DD")
//                             : getLabel(meet)
//                         ),
//                         datasets: [
//                           {
//                             label: "Streamed",
//                             data: SessionsData.map((meet) =>
//                               meet.streamed_count !== null
//                                 ? meet.streamed_count
//                                 : 0
//                             ),
//                             backgroundColor: "#265936",
//                             borderRadius: 50,
//                           },
//                         ],
//                       }}
//                       options={{
//                         responsive: true,
//                         scales: {
//                           x: {
//                             type: "category",

//                             display: true,
//                           },

//                           // x: {
//                           //   type: "time", // Use "time" for the x-axis scale type
//                           //   time: {
//                           //     unit: "day", // Display the labels in daily units
//                           //   },
//                           //   display: true,
//                           // },
//                         },
//                       }}
//                     />
//                   ) : (
//                     <div class="alert alert-danger w-50">
//                       No data available.
//                     </div>
//                   )}
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="col-12 col-sm-12 col-md-4 h-100">
//         <div className="chart_card bg-white h-100 overflow-hidden">
//           {/* <h3 className="p-3">Reported Meetings</h3> */}
//           <div className="glitch_list">
//             <RSSFeed barChartHeight={barChartHeight} />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default BarChart;

import React, { useEffect, useState, useRef } from "react";
import { Chart as Charts, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Api from "../Api/Api";
import "react-datepicker/dist/react-datepicker.css";
import "chartjs-adapter-moment";
import Datepickercomponent from "../util/Datepicker";
import RSSFeed from "./RSSFeed";
// Register all necessary Chart.js components
Charts.register(...registerables);
Charts.register(ChartDataLabels);

function BarChart() {
  const { SessionsCount } = Api();
  const moment = require("moment");
  const [SessionsData, setSessionsData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const BarChartRef = useRef(null);
  const [barChartHeight, setBarChartHeight] = useState(0);
  const [selectfromdate, setSelectedDate] = useState(null);
  const [selecttodate, setselectTodate] = useState(null);
  const [filter, setFilter] = useState(false);
  const [totalcount, setTotalcount] = useState(null);

  const handleFilterChange = async ({ from_date, to_date }) => {
    if (selectedFilter == "custom") {
      setFilter(true);
      try {
        setLoading(true);
        let body = {
          selectedFilter: selectedFilter,
          from_date: from_date ? moment(from_date).format("DD/MM/YYYY") : "",
          to_date: to_date ? moment(to_date).format("DD/MM/YYYY") : "",
        };
        const data = await SessionsCount(body);
        let filteredData = data.parameters.meetings.filter((meet) => {
          const meetDate = moment(meet.day, "YYYY-MM-DD");
          return (
            (!from_date || meetDate.isSameOrAfter(moment(from_date))) &&
            (!to_date || meetDate.isSameOrBefore(moment(to_date)))
          );
        });

        setSessionsData(filteredData);

        setTotalcount(data.parameters.sessionCount.totalCount);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
    if (selectedFilter !== "custom") {
      setFilter(false);
      setSelectedDate(null);
      setselectTodate(null);
      try {
        setLoading(true);

        let body = {
          selectedFilter: selectedFilter,
          from_date: "",
          to_date: "",
        };
        const data = await SessionsCount(body);
        setSessionsData(data.parameters.meetings);
        setTotalcount(data.parameters.sessionCount.totalCount);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
  };

  useEffect(() => {
    handleFilterChange({
      from_date: selectfromdate,
      to_date: selecttodate,
      selectedFilter: selectedFilter,
    });
  }, [selectedFilter]);

  const getLabel = (meet) => {
    switch (selectedFilter) {
      case "daily":
        return meet.day;
      case "weekly":
        return meet.day;
      case "monthly":
        return meet.month;
      case "yearly":
        return meet.year;
      default:
        return meet.month;
    }
  };

  useEffect(() => {
    if (BarChartRef.current) {
      const height = BarChartRef.current.clientHeight;
      setBarChartHeight(height);
    }
  }, [SessionsData]);

  const handleDateChange = (date) => {
    if (date) {
      const formatdate = moment(date).format("DD/MM/YYYY");
      setSelectedDate(date);
      handleFilterChange({ from_date: date, to_date: selecttodate });
    }
  };

  const handleTodate = (date) => {
    setselectTodate(date);
    handleFilterChange({ from_date: selectfromdate, to_date: date });
  };

  return (
    <>
      <div className="col-12 col-sm-12 col-md-8 mb-3">
        <div
          className="chart_card bg-white p-3 position-relative bar_chart"
          ref={BarChartRef}
        >
          <div className="d-flex align-items-center py-2">
            <div>
              <h3 className="mb-0">Sessions Count</h3>
              <small className="fw-bold">
                Total Meetings count : {totalcount}
              </small>
            </div>
            {filter && (
              <>
                <div className="from_date-filter filter_col date_picker px-4">
                  <Datepickercomponent
                    selecteddate={selectfromdate}
                    handleDate={handleDateChange}
                    placeholderText="From Date"
                  />
                </div>
                <div className="to_date-filter filter_col date_picker">
                  <Datepickercomponent
                    selecteddate={selecttodate}
                    handleDate={handleTodate}
                    placeholderText="To Date"
                  />
                </div>
              </>
            )}
          </div>
          <div>
            <div className="profile_form session_count-dropdown">
              <select
                name="status"
                id="status"
                value={selectedFilter}
                onChange={(e) => setSelectedFilter(e.target.value)}
              >
                <option value="all">All</option>
                <option value="daily">Day</option>
                <option value="weekly">Week</option>
                <option value="monthly">Month</option>
                <option value="yearly">Year</option>
                <option value="custom">custom</option>
              </select>
            </div>
            <div>
              {loading ? (
                <p className="text-center">Loading...</p>
              ) : SessionsData && SessionsData.length > 0 ? (
                <Bar
                  data={{
                    labels: SessionsData.map((meet) =>
                      meet.day
                        ? moment(meet.day).format("YYYY-MM-DD")
                        : getLabel(meet)
                    ),
                    datasets: [
                      {
                        label: "Streamed",
                        data: SessionsData.map((meet) =>
                          meet.streamed_count !== null ? meet.streamed_count : 0
                        ),
                        backgroundColor: "#265936",
                        borderRadius: 50,
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    plugins: {
                      datalabels: {
                        display: true,
                        color: "white",
                        align: "start",
                        anchor: "end",
                        font: {
                          size: 10,
                          weight: "bold",
                        },
                        formatter: (value) => value,
                        padding: {
                          top: 0,
                          bottom: 10,
                        },
                      },
                    },
                    scales: {
                      x: {
                        type: "category",
                        display: true,
                      },
                    },
                  }}
                />
              ) : (
                <div className="alert alert-danger w-50">
                  No data available.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 col-sm-12 col-md-4 h-100">
        <div className="chart_card bg-white h-100 overflow-hidden">
          <div className="glitch_list">
            <RSSFeed barChartHeight={barChartHeight} />
          </div>
        </div>
      </div>
    </>
  );
}

export default BarChart;
