import React from "react";

class VideoDuration extends React.Component {
  render() {
    const moment = require("moment");
    const { from_time, to_time } = this.props;

    // Convert time strings to Date objects
    const fromDate = new Date(`2000-01-01T${from_time}`);
    const toDate = new Date(`2000-01-01T${to_time}`);

    // Calculate the duration in milliseconds
    const durationMs = toDate - fromDate;
    if (durationMs < 0) {
      return (
        <div className="video_duration">
          Start time : {moment(from_time, "HH:mm:ss").format("HH:mm")}
        </div>
      );
    }
    // Convert milliseconds to hours and minutes
    const hours = Math.floor(durationMs / (1000 * 60 * 60));
    const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));

    return (
      <div className="video_duration">
        {hours} Hours {minutes} Mins
      </div>
    );
  }
}

export default VideoDuration;
