import React, { useEffect, useRef, useState, useCallback } from "react";
import "./LiveVideos.css";
import videoImg from "../../assets/images/video_thum.png";
import { Link } from "react-router-dom";
import playIcon from "../../assets/images/play_icon.svg";
import fbIcon from "../../assets/images/fb_icon.svg";
import twitterIcon from "../../assets/images/twitter_icon.svg";
import { FaCircle } from "react-icons/fa";
import "bootstrap-datepicker";
import "../../../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
import Api from "../Api/Api";
import PreviousSessionMeetings from "./PreviousSessionMeetings";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import nolivemeeting from "../../assets/images/nolivemeeting.jpg";
import noupcoming from "../../assets/images/noupcoming_meeting.jpg";
import VideoDuration from "../util/VideoDuration";

function LiveVideos() {
  const moment = require("moment");
  const [isVisible, setIsVisible] = useState(false);
  const [getVideoData, setGetVideoData] = useState([]);
  const videoBlogCardRef = useRef([]);
  const [VideoMessage, setVideoMessage] = useState("");
  const [UpcomingVideo, setUpcomingVideo] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const { LiveVideoAPI, UpcomingVideoAPI } = Api();

  const [count, setcount] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      GetLiveVideo();
      UpcomingVideos();
      setcount(count + 1);
    }, 30000);
  }, [count]);

  const [firsttime, setFirsttime] = useState(true);
  useEffect(() => {
    if (firsttime) {
      GetLiveVideo();
      UpcomingVideos();
      setFirsttime(false);
    }
  }, []);

  const GetLiveVideo = async () => {
    try {
      let data = await LiveVideoAPI();
      setGetVideoData(data.parameters);
      setVideoMessage(data);
    } catch (err) {
      console.log(err);
    }
  };

  const UpcomingVideos = async () => {
    try {
      let data = await UpcomingVideoAPI();
      setUpcomingVideo(data.parameters.data);
    } catch (err) {
      console.log(err);
    }
  };

  const noupcomings = [{ id: 1, img: noupcoming, alt: "upcoming imga" }];

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="page_container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="breadcrumb"></div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12 col-sm-12 col-md-9 mb-3">
            <div className="col-12">
              <div className="page_title">Live Meetings</div>
            </div>
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              navigation={true}
              modules={[Pagination, Navigation]}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 1,
                },
                769: {
                  slidesPerView: 1,
                },
              }}
              className="swiper_card live_videos-slider"
            >
              {getVideoData.length > 0 ? (
                Array.isArray(getVideoData) &&
                getVideoData.map((data, index) => (
                  <SwiperSlide key={index}>
                    <div className="video_blog-card" ref={videoBlogCardRef}>
                      <>
                        <Link
                          to={`/user/livevideos/meeting-detail/${data.id}`}
                          className="text-decoration-none"
                        >
                          <div className="live_video-banner mb-3 position-relative">
                            {data.cover_link && <img
                              src={data.cover_link}
                              alt={data.video_title}
                            />}
                            {!data.cover_link && <img src={videoImg} />}
                          </div>
                        </Link>
                        <div className="video_description mb-3 d-flex justify-content-between align-items-center">
                          <div>
                            <Link
                              to={`/user/livevideos/meeting-detail/${data.id}`}
                              className="text-decoration-none"
                            >
                              <div className="live_video-title">
                                {data.video_title || "No live"}
                              </div>
                            </Link>
                            <div className="live_video-section">
                              {data.video_description && data.video_description}
                            </div>
                          </div>
                          <div>
                            <div className="video_social-media">
                              <ul className="action_list">
                                <li>
                                  <Link
                                    to={data.youtube_link && data.youtube_link}
                                    target="_blank"
                                  >
                                    <img src={playIcon} alt="play" />
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={
                                      data.facebook_link && data.facebook_link
                                    }
                                    target="_blank"
                                  >
                                    <img src={fbIcon} alt="facebook" />
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={data.twitter_link && data.twitter_link}
                                    target="_blank"
                                  >
                                    <img src={twitterIcon} alt="twitter" />
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="video_other d-flex justify-content-between align-items-center">
                          <div className="video_status d-flex align-items-center">
                            <FaCircle className="me-2" />
                            {VideoMessage.message && VideoMessage.message}
                          </div>
                        </div>
                      </>
                    </div>
                  </SwiperSlide>
                ))
              ) : (
                <div className="video_blog-card">
                  <img
                    src={nolivemeeting}
                    alt=""
                    style={{ width: "100%", borderRadius: "12px" }}
                  />
                </div>
              )}
            </Swiper>
          </div>
          <div className="col-12 col-sm-12 col-md-3 mb-3">
            <div className="page_title pb-3">Upcoming Meetings</div>
            <div
              className="upcoming_session-card slimscroll"
              style={{ height: "80vh" }}
            >
              {UpcomingVideo.length > 0 ? (
                Array.isArray(UpcomingVideo) &&
                UpcomingVideo.map((data, index) => (
                  <div
                    className="video_blog-card p-3 mb-3 position-relative "
                    key={index}
                    style={{
                      overflow: "hidden",
                    }}
                  >
                    <Link
                      to={`/user/livevideos/meeting-detail/${data.id}`}
                      className="text-decoration-none"
                    >
                      <div className="video_banner mb-3 position-relative">
                        {data.cover_link && <img
                          src={data.cover_link}
                          alt={data.video_title}
                          loading="lazy"
                        />}
                        {!data.cover_link && <img src={videoImg} />}
                      </div>
                      <div className="video_description ">
                        <div className="video_title">
                          {data.video_title && data.video_title}
                        </div>
                      </div>
                      <div
                        className="d-flex justify-content-between"
                        style={{ padding: "20px 0px" }}
                      >
                        <small className="video_date">
                          {" "}
                          Start time{" "}
                          {data.from_time
                            ? moment(data.from_time, "HH:mm:ss").format("HH:mm")
                            : "00:00"}
                        </small>
                        <div
                          style={{
                            fontSize: "13px",
                            color: "#5C5C5C",
                            fontFamily: "sans-serif",
                            fontWeight: "500",
                          }}
                        >
                          {" "}
                          (
                          {data.from_time
                            ? moment(data.from_time, "HH:mm:ss").format("HH:mm")
                            : "00:00"}
                          -
                          {data.to_time
                            ? moment(data.to_time, "HH:mm:ss").format("HH:mm")
                            : "00:00"}
                          )
                        </div>
                      </div>
                      <div className="upcoming_meeting_status">
                        <span className="video_date pe-2">Status :</span>
                        <button
                          className={
                            data.status === "COMPLETED"
                              ? "btn custom_btn-success ws-75"
                              : data.status === "UPCOMING"
                                ? "btn custom_btn-info ws-75"
                                : "btn custom_btn-warning ws-75"
                          }
                        >
                          {data.status === "COMPLETED"
                            ? "Completed"
                            : data.status === "UPCOMING"
                              ? "Upcoming"
                              : "Live"}
                        </button>
                      </div>
                    </Link>
                  </div>
                ))
              ) : (
                <>
                  {noupcomings.map((item, index) => (
                    <div className="video_blog-card p-2 mb-2" key={index}>
                      <div className="video_banner position-relative">
                        <img src={item.img} />
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="previous_video-card">
              <PreviousSessionMeetings />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LiveVideos;
